import 'common-tags';

var _taggedTemplateLiteral = (function (strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
});

var _templateObject = _taggedTemplateLiteral(['^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\n    \\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]\n    {1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'], ['^(([^<>()\\\\[\\\\]\\\\\\.,;:\\\\s@"]+(\\\\.[^<>()\\\\[\\\\]\n    \\\\\\.,;:\\\\s@"]+)*)|(".+"))@((\\\\[[0-9]{1,3}\\\\.[0-9]{1,3}\\\\.[0-9]{1,3}\\\\.[0-9]\n    {1,3}])|(([a-zA-Z\\\\-0-9]+\\\\.)+[a-zA-Z]{2,}))$']);

/* AUTO INCREASE LANDING PAGE COUNTER */
function addCommas(nStr) {
  nStr = String(nStr);
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
}

var userCount = void 0;
var userCountSpeed = void 0;
var projectCount = void 0;
var projectCountSpeed = void 0;
var userElements = [];
var projectElements = [];

// Create a new promise to check for the AJAX call to get the site stats info.
var initPromise = new Promise(function (resolve, reject) {
  fetch('/ajax/_general_vars.php', {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (response) {
    if (response.ok) {
      return response.json();
    }

    var error = new Error(response.statusText);
    error.response = response;
    reject(error);
  }).then(function (json) {
    resolve(json);
  });
});

// Create another promise to check for the incrementer settings to increment
// the site stats periodically.
var incrementerPromise = new Promise(function (resolve, reject) {
  fetch('/ajax/count-24h.php', {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (response) {
    if (response.ok) {
      return response.json();
    }

    var error = new Error(response.statusText);
    error.response = response;
    reject(error);
  }).then(function (json) {
    resolve(json);
  });
});

// Get both promises and wait until both are finished, then initialise the
// site stats.
Promise.all([initPromise, incrementerPromise]).then(function (values) {
  userCountSpeed = 86400000 / values[1].user_count_24h;
  userCount = parseInt(values[0].user_count, 10);

  projectCountSpeed = 86400000 / values[1].project_count_24h;
  projectCount = parseInt(values[0].project_count, 10);

  updateElements(userElements, userCount, userCountSpeed);
  updateElements(projectElements, projectCount, projectCountSpeed);
}).catch(function (error) {
  throw error;
});

function updateElements(elements, currentCount, speed) {
  var processStatsUpdate = function processStatsUpdate() {
    elements.map(function (element) {
      element.textContent = addCommas(currentCount);
      return element;
    });
    currentCount = parseInt(currentCount, 10) + 1;
  };

  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(processStatsUpdate, { timeout: 30000 });
  } else {
    processStatsUpdate();
  }

  setTimeout(function () {
    return updateElements(elements, currentCount, speed);
  }, speed);
}

function updateUserCount(element) {
  userElements.push(element);
}

function updateProjectCount(element) {
  projectElements.push(element);
}

// Set the new registered users and total jobs posted count
var registeredUsersCount = document.getElementById('footerRegisteredUsersCount');
var totalJobsPostedCount = document.getElementById('footerTotalJobsPostedCount');

updateUserCount(registeredUsersCount);
updateProjectCount(totalJobsPostedCount);
